$(document).on('turbolinks:load', function() {
  if (($('#notification_team_id').length > 0)) {
    if ($('#notification_team_id').val().length > 0 && $('#notification_team_id').val() != "Please Select") {
      $('#notification_user_selection').show();
      $.ajax({
        url: `/${determineControllerPath(window.location.href)}/filter_notification_users`,
        method: "GET",
        dataType: "json",
        data: { team_id: $('#notification_team_id').val() },
        error: function(xhr, status, error) {
          alert(`AJAX Error: ${status} ${error}`);
        },
        success: function(response) {
          getAndFilterUsers(response);
        }
      });
    } else {
      $('#notification_user_selection').hide();
    }
    $(document).on("change", '#notification_team_id', function() {
      let team_id = $(this).val();
      if (team_id.length > 0) {
        $('#notification_user_selection').show();
        $.ajax({
          url: `/${determineControllerPath(window.location.href)}/filter_notification_users`,
          method: "GET",
          dataType: "json",
          data: { team_id: team_id },
          error: function(xhr, status, error) {
            alert(`AJAX Error: ${status} ${error}`);
          },
          success: function(response) {
            // These lines reset the child values to avoid incongruous behavior
            user_ids = $("#notification_user_ids").val();
            if (user_ids != null) {
              $('#notification_user_ids')[0].selectize.setValue(null, true);
            };
            getAndFilterUsers(response);
          }
        });
      } else {
        $('#notification_user_selection').hide();
      }
    });
  }
});

function getAndFilterUsers(response) {
  let users = response["users"];
  let users_dropdown = $("#notification_user_ids")[0].selectize
  users_dropdown.clearOptions();
  $(users).each(function() {
    users_dropdown.addOption({ value: this[0], text: this[1] });
  });
}