window.submitAssessmentAnswer = function (div) {
  if ($("#take-an-assessment").length > 0) {
    let insert = true;
    let answer = "";
    if (
      $(div).attr("data-model") == "binary" ||
      $(div).attr("data-model") == "multiple-choice-one-answer" ||
      $(div).attr("data-model") == "multiple-choice-multiple-answers"
    ) {
      // Disable all answer boxes to prevent further submissions
      $(".csl-answer-box.box").each(function () {
        if ($(div).attr("data-model") != "multiple-choice-multiple-answers") {
          this.classList.remove("active");
        }
        this.classList.add("disabled");
      });
    }
    if (
      $(div).attr("data-model") == "binary" ||
      $(div).attr("data-model") == "multiple-choice-one-answer"
    ) {
      //remove class so only 1 active div at a time
      $(".active").removeClass("active");
      //set active and send through answer.
      $(div).addClass("active");
      answer = $(div).text();
    } else if (
      $(div).attr("data-model") == "multiple-choice-multiple-answers"
    ) {
      //if already active, remove active
      if ($(div).hasClass("active")) {
        $(div).removeClass("active");
        insert = false;
      } else {
        //set active and send through answer.
        $(div).addClass("active");
      }
      answer = $(div).text();
    } else if ($(div).attr("data-model") == "medicine-calculation") {
      if ($(div).attr("type") == "text") {
        answer = [$(div).val(), $("#question-dropdown").text()];
      } else {
        answer = [$("#question-input").val(), $(div).text()];
      }
    } else if ($(div).attr("data-model") == "free-text") {
      answer = $("#free-text-question-input").val();
    }
    //change button text
    $("a:contains('Skip and go to next question')").text("Next question");
    let id = window.location.href
      .substring(window.location.href.lastIndexOf("/") + 1)
      .split("?")[0];
    $.ajax({
      url: `/${determineControllerPath(window.location.href)}/assessments/${id}/submit_answer`,
      method: "POST",
      dataType: "json",
      data: {
        data: $(div).attr("data-id"),
        answer: answer,
        model: $(div).attr("data-model"),
        insert: insert,
      },
      error: function (xhr, status, error) {
        alert(`AJAX Error: ${status} ${error}`);
        // Enable answer boxes again if there's an error
        $(".csl-answer-box.box").removeClass("disabled");
      },
      success: function (response) {
        // Enable answer boxes after success
        $(".csl-answer-box.box").removeClass("disabled");
      },
    });
  }
};

window.showNotesModal = function () {
  $("#assessment_notes_modal").addClass("is-active");
};

window.submitNoteChange = function (div) {
  let id = window.location.href
    .substring(window.location.href.lastIndexOf("/") + 1)
    .split("?")[0];
  $.ajax({
    url: `/${determineControllerPath(window.location.href)}/assessments/${id}/submit_note`,
    method: "POST",
    dataType: "json",
    data: { note: $(div).val() },
    error: function (xhr, status, error) {
      alert(`AJAX Error: ${status} ${error}`);
    },
    success: function (response) {},
  });
};

window.determineConfirmMessage = function (assessment_id, position) {
  event.preventDefault();
  $.ajax({
    url: `/${determineControllerPath(window.location.href)}/assessments/${assessment_id}/confirm_message`,
    method: "GET",
    dataType: "json",
    error: function (xhr, status, error) {
      alert(`AJAX Error: ${status} ${error}`);
    },
    success: function (response) {
      if (
        confirm(response["success"]) == true &&
        response["success"].includes("available") == false
      ) {
        // continue to url
        // add question_pos param if available
        if (position.length > 0) {
          location.href = `/${determineControllerPath(window.location.href)}/assessments/${assessment_id}?question_pos=${position}`;
        } else {
          location.href = `/${determineControllerPath(window.location.href)}/assessments/${assessment_id}`;
        }
      }
    },
  });
};
